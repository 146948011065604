<template>
  <pvp-modal width="580" :value="value" @input="closeModal">
    <template slot="modal-title">{{
      $t('organizations.member_deletion')
    }}</template>
    <i18n path="organizations.member_deleteConfirmation">
      <b slot="username">{{ user.name }}</b>
    </i18n>
    <div class="buttons">
      <pvp-btn variant="secondary" @click="closeModal">{{
        $t('global.cancel')
      }}</pvp-btn>
      <pvp-btn @click="deleteMember">{{
        $t('global.delete')
      }}</pvp-btn>
    </div>
  </pvp-modal>
</template>

<script>
export default {
  name: 'OrganizationDeleteMember',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters('users', ['getUser']),
    user() {
      return this.getUser(this.hash);
    },
  },
  methods: {
    closeModal() {
      this.$emit('input', false);
    },
    deleteMember() {
      this.$emit('success', this.hash);
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-modal {
  text-align: center;
}

.buttons {
  margin-top: 30px;
}

.button {
  width: 160px;

  @include min-tablet() {
    & + & {
      margin-left: 12px;
    }
  }
  @include max-tablet() {
    margin: 12px 6px 0;
  }
}
</style>
