<template>
  <div class="new-organization-form">
    <template v-if="isCreated">
      <InfoBox variant="success">
        <i18n path="organizations.create_created">
          <b slot="name">{{ organization.name }}</b>
        </i18n>
      </InfoBox>
      <div class="form-controls">
        <BaseLink
          :to="{
            name: 'organization',
            params: { id: organization.id },
          }"
        >
          {{ $t('organizations.toLink') }}
        </BaseLink>
      </div>
    </template>
    <InfoBox v-else-if="isEdited" variant="success">
      <i18n path="organizations.edit_edited">
        <b slot="name">{{ organization.name }}</b>
      </i18n>
    </InfoBox>
    <template v-else>
      <BaseForm :validate="onValidate" @submit.prevent="onSubmit">
        <BaseInput
          v-model="name"
          :required="true"
          :label="$t('organizations.name')"
          :error-message.sync="errors.name"
          :placeholder="$t('inputs.title')"
          @input="validateInputName($event, 'name')"
        />

        <BaseInput
          v-model="description"
          :is-area="true"
          rows="3"
          :error-message.sync="errors.description"
          :label="$t('global.description')"
          :placeholder="$t('inputs.useful')"
          @input="validateInputDescription($event, 'description')"
        />

        <BaseInput
          v-model="discord"
          label="Discord"
          :error-message.sync="errors.discord"
          placeholder="example#1337"
          @input="validateInputDiscord($event, 'discord')"
        />

        <BaseInput
          v-model="website"
          :label="$t('global.site')"
          :error-message.sync="errors.website"
          placeholder="https://example.com"
          @input="validateInputWebsite($event, 'website')"
        />

        <BaseInput
          v-model="more"
          :is-area="true"
          rows="3"
          :error-message.sync="errors.more"
          :placeholder="$t('info.additional_title')"
          :label="$t('global.additionally')"
          @input="validateInputMore($event, 'more')"
        />

        <div v-if="variant === 'create'" class="logo-upload">
          <div class="label">{{ $t('organizations.logo') }}</div>
          <div v-if="files.length && !filesHasErrors" class="cropper">
            <pvp-btn
              type="button"
              icon-left="cross"
              variant="clear"
              @click="clearFiles"
            ></pvp-btn>
            <image-cropper
              :src="files[0].src"
              :width="250"
              :height="250"
              @result="afterCropping"
            />
          </div>
          <files-uploader v-else v-model="files" :max-file-size="5" />
        </div>

        <InfoBox v-if="hasErrors" variant="error">
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="errors.common"></div>
        </InfoBox>

        <div class="form-controls">
          <pvp-btn
            v-if="variant === 'create'"
            type="submit"
            :is-loading="loadingState.create"
            :disabled="filesHasErrors || hasValidationError"
          >
            {{ $t('organizations.create_title') }}
          </pvp-btn>
          <pvp-btn
            v-if="variant === 'edit'"
            type="submit"
            :is-loading="loadingState.edit"
          >
            {{ $t('save.single') }}
          </pvp-btn>
        </div>
      </BaseForm>
    </template>
  </div>
</template>

<script>
import FilesUploader from '@components/BaseComponents/FilesUploader.vue';
import ImageCropper from '@components/BaseComponents/ImageCropper.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import { isNull } from 'lodash';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'NewOrganizationForm',
  components: {
    ImageCropper,
    FilesUploader,
    BaseForm,
    BaseInput,
    BaseLink,
    InfoBox,
  },
  props: {
    /**
     * variant: 'create', 'edit'
     */
    variant: {
      type: String,
      default: 'create',
    },
    id: {
      type: Number,
      default: -1,
    },
  },
  data: () => ({
    loadingState: {
      edit: false,
      create: false,
    },

    organizationId: null,

    // Data
    files: [],
    cropResult: {},
    name: '',
    description: '',
    discord: '',
    website: '',
    imageId: null,
    bgImageId: null,
    more: '',

    // Errors
    errors: {
      name: '',
      common: '',
      discord: '',
      website: '',
      more: '',
    },
    success: false,

    isCreated: false,
    isEdited: false,
  }),
  computed: {
    ...mapGetters('organizations', ['getOrganization']),

    hasErrors() {
      return !!this.errors.common;
    },

    hasValidationError() {
      return Object.values(this.errors).some((val) => {
        if (!isNull(val)) {
          return val.length > 0;
        }
      });
    },

    organization() {
      return this.getOrganization(this.organizationId);
    },

    filesHasErrors() {
      return this.files.some(({ error }) => error.length);
    },
  },
  created() {
    switch (this.variant) {
      case 'edit':
        this.organizationId = this.id;
        if (this.organization) {
          this.name = this.organization.name;
          this.description = this.organization.description;
          this.discord = this.organization.contacts.discord;
          this.icq = this.organization.contacts.icq;
          this.website = this.organization.contacts.website;
          this.more = this.organization.contacts.contacts;
        }
        break;
      default:
    }
  },
  methods: {
    validateInputName(value, fieldName) {
      const regExpDigitsAndNumbers = /^[a-zA-Za-яА-Я0-9_. ]*$/;
      const isNumbersAndDigits = regExpDigitsAndNumbers.test(value);
      if (!isNumbersAndDigits) {
        this.errors[fieldName] = this.$t(
          'errors.only_numbers_and_digits',
        );
        if (value.length > 40) {
          this.errors[fieldName] = this.$t('errors.max_length_40');
          this.name = value.slice(0, 41);
        }
      } else if (value.length > 40) {
        this.errors[fieldName] = this.$t('errors.max_length_40');
        this.name = value.slice(0, 41);
      } else if (value.length < 1) {
        this.errors[fieldName] = this.$t('errors.min_length');
      } else {
        this.errors[fieldName] = '';
      }
    },

    validateInputDescription(value, fieldName) {
      if (value.length > 1000) {
        this.errors[fieldName] = this.$t('errors.max_length_1000');
        this.description = value.slice(0, 1001);
      } else {
        this.errors[fieldName] = '';
      }
    },

    validateInputDiscord(value, fieldName) {
      if (value.length > 32) {
        this.errors[fieldName] = this.$t('errors.max_length_32');
        this.discord = value.slice(0, 33);
      } else {
        this.errors[fieldName] = '';
      }
    },

    validateInputWebsite(value, fieldName) {
      const regWebsite = /^(http|https):\/\/[^ "]+$/;
      const isWebsite = regWebsite.test(value);

      if (value === '') {
        this.errors[fieldName] = '';
      } else if (value.length > 50) {
        this.errors[fieldName] = this.$t('errors.max_length_50');
        this.website = value.slice(0, 51);
      } else if (!isWebsite) {
        this.errors[fieldName] = this.$t(
          'errors.website_wrong_format',
        );
      } else {
        this.errors[fieldName] = '';
      }
    },

    validateInputMore(value, fieldName) {
      if (value.length > 255) {
        this.errors[fieldName] = this.$t('errors.max_length_255');
        this.more = value.slice(0, 256);
      }
    },

    logoDeleted() {
      this.imageId = null;
    },

    logoUploaded(logo) {
      this.imageId = logo.fileId;
    },

    onSubmit() {
      switch (this.variant) {
        case 'edit':
          this.edit();
          break;
        default:
          this.createNewOrganization();
      }
    },

    async createNewOrganization() {
      if (this.loadingState.create) {
        return;
      }
      this.success = false;
      this.errors.common = null;
      this.loadingState.create = true;
      const url = 'v2/profile/organization';

      const form = {
        name: this.name,
        contacts: this.more,
        description: this.description,
        discord: this.discord,
        website: this.website,
      };

      const formData = new FormData();

      Object.entries(form).forEach(([key, value]) =>
        formData.append(key, value),
      );

      if (this.files.length) {
        formData.append('file', this.files?.[0]?.file);
        Object.entries(this.cropResult).forEach(([key, value]) =>
          formData.append(key, value),
        );
      }

      try {
        const { success, organization } = await api.post(
          url,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );
        if (success) {
          this.$store.dispatch(
            'organizations/storeOrganization',
            organization,
          );
          this.$router.push({
            name: 'organization',
            params: {
              id: organization.id,
            },
          });
        }
      } catch ({ error = '' }) {
        if (_.isArray(error)) {
          this.errors.common = error.join(', ');
        } else if (_.isObject(error)) {
          Object.entries(error).forEach((item) => {
            if (this.errors[item[0]] !== undefined) {
              this.errors[item[0]] = item[1].join(', ');
            }
          });
        } else {
          this.errors.common = this.$t('organizations.errors_common');
        }
      } finally {
        this.loadingState.create = false;
      }
    },

    edit() {
      if (this.loadingState.edit) {
        return;
      }
      this.success = false;
      this.errors.common = null;
      this.loadingState.edit = true;

      const form = {
        id: this.id,
        name: this.name,
        contacts: this.more,
        description: this.description,
        discord: this.discord,
        website: this.website,
      };

      if (this.imageId) {
        form.imgLogo = this.imageId;
      }

      api
        .post('/profile/updateorg', { form })
        .then(() => {
          this.success = true;
          this.$emit('success');
          this.isEdited = true;
        })
        .catch(({ error = '' }) => {
          if (_.isArray(error)) {
            this.errors.common = error.join(', ');
          } else if (_.isObject(error)) {
            Object.entries(error).forEach((item) => {
              if (this.errors[item[0]] !== undefined) {
                this.errors[item[0]] = item[1].join(', ');
              }
            });
          } else {
            this.errors.common = this.$t(
              'organizations.errors_common',
            );
          }
        })
        .finally(() => {
          this.loadingState.edit = false;
        });
    },

    onValidate() {
      this.errors.name = this.name
        ? ''
        : this.$t('organizations.errors_title');
      return this.name;
    },

    clearFiles() {
      this.files = [];
    },

    afterCropping(result) {
      this.cropResult = result;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-organization-form {
  font-size: 14px;

  .form-controls {
    margin-top: 30px;
    text-align: center;
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading-text {
      font-size: 18px;
      font-weight: 600;
    }

    .registration-loader {
      margin-top: 20px;
    }
  }
}

.logo-upload {
  width: 100%;

  .label {
    font-size: em(14px);
    color: rgba(white, 0.5);
    margin: 16px 0 6px;
  }
}

.cropper {
  position: relative;
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }

  .image-cropper {
    background-color: transparent;
    border: 1px dashed rgba(white, 0.1);
  }

  .button-clear {
    position: absolute;
    right: 0;
    color: rgba(white, 0.4);
    font-size: 16px;
    padding: 12px;
  }
}
</style>
