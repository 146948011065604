<template>
  <article class="contact-card">
    <div class="contact-card__image-wrapper">
      <img
        class="contact-card__image"
        :src="imageSrc"
        alt="contact card image"
      />
      <div class="contact-card__fade"></div>
    </div>
    <div class="contact-card__text">
      <h3 class="contact-card__title">{{ title }}</h3>
      <p class="contact-card__subtitle">{{ subtitle }}</p>
      <a class="contact-card__button" :href="link" target="_blank">{{
        buttonText
      }}</a>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ContactCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.contact-card {
  width: 100%;
  background: #100f0f;
  border: 1px solid #f5f5f71f;
  border-radius: 8px;

  @include min-tablet() {
    // TODO Убрать imporatnt после замены глобальных стилей ПВП.
    display: flex !important;
  }
}

.contact-card__image-wrapper {
  position: relative;
  border-radius: inherit;

  @include min-tablet() {
    width: 56%;
    height: auto;
  }
}

.contact-card__image {
  display: block;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 300px;

  @include min-tablet() {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: unset;
  }
}

.contact-card__fade {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(16, 15, 15, 0) 1%,
    #100f0f 99%
  );

  @include min-tablet() {
    background: linear-gradient(
      to left,
      rgba(16, 15, 15, 0) 5%,
      #100f0f 95%
    );
  }
}

.contact-card__text {
  padding: 16px;

  @include min-tablet() {
    order: -1;
    width: 44%;
    padding: 64px 0 64px 64px;
  }
}

.contact-card__title {
  color: #f5f5f7;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.3px;
  margin-bottom: 12px;
  font-weight: 700;

  @include min-tablet() {
    font-size: 32px;
    line-height: 125%;
  }
}

.contact-card__subtitle {
  color: #f5f5f7;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
}

.contact-card__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 40px;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
  background: #e2e3e7;
  border-radius: 6px;

  &:hover {
    text-decoration: none;
    color: #000000;
  }

  @include min-tablet() {
    display: inline-block;
  }
}
</style>
