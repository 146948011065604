<template>
  <pvp-modal :value="value" width="500" @input="toggleModal">
    <div v-if="title" slot="modal-title">{{ title }}</div>
    <organization-form
      v-if="variant !== 'leave'"
      :id="id"
      :variant="variant"
      @success="onSuccess"
    />
    <div v-else class="leave">
      <InfoBox v-if="isSuccess" variant="success">
        {{ $t('organizations.leave_left') }}
      </InfoBox>
      <template v-else>
        <i18n
          path="organizations.leave_title"
          class="leave-text"
          tag="p"
        >
          <b slot="name">{{ organization.name }}</b>
        </i18n>
        <pvp-btn
          :is-loading="loadingState.leave"
          @click="leaveOrganization"
          >{{ $t('global.confirm') }}
        </pvp-btn>
      </template>
    </div>
    <InfoBox v-if="error" variant="error">{{ error }}</InfoBox>
    <div v-if="isSuccess" slot="modal-footer">
      <pvp-btn @click="toggleModal(false)">{{
        $t('global.close')
      }}</pvp-btn>
    </div>
  </pvp-modal>
</template>

<script>
import OrganizationForm from './NewOrganizationForm.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'OrganizationSettings',
  components: {
    OrganizationForm,
    InfoBox,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    value: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: '',
    },
    hash: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loadingState: {
      leave: false,
    },
    error: '',
    isSuccess: false,
  }),
  computed: {
    ...mapGetters('organizations', ['getOrganization']),
    organization() {
      return this.getOrganization(this.id);
    },
    title() {
      return {
        edit: this.$t('organizations.edit_title'),
        create: this.$t('organizations.create_title'),
      }[this.variant];
    },
  },
  methods: {
    onSuccess(data) {
      this.isSuccess = true;

      this.$emit('success', {
        data,
        variant: this.variant,
      });
    },

    toggleModal(isOpen) {
      this.$emit('input', isOpen);
      this.isSuccess = false;
    },

    leaveOrganization() {
      this.error = '';
      this.loadingState.leave = true;

      api
        .post(`/profile/${this.hash}/leaveorg`, {
          form: {
            id: this.id,
          },
        })
        .then(() => {
          this.onSuccess();
        })
        .catch(({ error = '' }) => {
          this.error = error;
        })
        .finally(() => {
          this.loadingState.leave = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.leave {
  text-align: center;
}

.box-error {
  margin-top: 10px;
}
</style>
