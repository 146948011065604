<template>
  <div class="tournament-status">
    <span class="status-text">
      {{ $t('organizations.currentStatus') }}
    </span>
    {{ tournament.status.name }}
    <div v-if="!isHub" class="text-countdown">
      {{ countdown.text }}
      <br />
      <time-left v-if="countdown.ts" :to="countdown.ts" />
    </div>
    <div v-else class="text-hub-status">
      {{ hubStatuses(tournament.status.code) }}
    </div>
    <pvp-btn
      :to="{
        name: 'tournament-edit',
        params: { tnId: tournament.id, orgId: organizationId },
      }"
      :variant="appIsLarge ? 'link' : 'primary'"
      icon-left="settings"
    >
      <span class="button-text">{{ $t('global.edit') }}</span>
    </pvp-btn>
  </div>
</template>

<script>
export default {
  name: 'TournamentStatusEdit',
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
    tournamentId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('application', ['appIsLarge']),

    tournament() {
      return this.getTournament(this.tournamentId);
    },
    isHub() {
      const hubIdSystem = 9;
      return this.tournament.idSystem === hubIdSystem;
    },
    countdown() {
      const { tsStartReg, tsEndReg, tsStartRun, tsEndRun } =
        this.tournament;

      const time = [
        {
          text: this.$t('tournaments.timeleftTo_registrationStart'),
          ts: tsStartReg,
        },
        {
          text: this.$t('tournaments.timeleftTo_registrationEnd'),
          ts: tsEndReg,
        },
        {
          text: this.$t('tournaments.timeleftTo_tournamentStart'),
          ts: tsStartRun,
        },
        {
          text: this.$t('tournaments.timeleftTo_tournamentEnd'),
          ts: tsEndRun,
        },
      ].find(({ ts }) => ts > +(Date.now() / 1000).toFixed(0)) || {
        text: this.$t('tournaments.timeleftTo_expired'),
        ts: null,
      };

      return time;
    },
  },
  methods: {
    hubStatuses(status) {
      const statuses = {
        cancelled: this.$t('tournaments.status_canceled'),
        published: this.$t('tournaments.state_announced'),
        finished: this.$t('tournaments.status_completed'),
        created: this.$t('tournaments.hub_not_announced'),
        executing: this.tournament?.isHubMatchMakingEnabled
          ? this.$t('tournaments.hub_is_active')
          : this.$t('tournaments.hub_is_not_active'),
      };
      return statuses[status];
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament-status {
  position: relative;
  padding-right: 1em;
}

.status-text {
  @include min-laptop() {
    display: none;
  }
  color: rgba(white, 0.6);
  margin-right: 1em;
}

.text-countdown,
.text-hub-status {
  margin-top: 0.5em;
  color: rgba(white, 0.5);
  line-height: 1.2;
}

.button {
  @include min-laptop() {
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.5em;
    &-text {
      display: none;
    }
  }

  @include max-laptop() {
    margin-top: 1em;
  }
}
</style>
