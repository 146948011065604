<template>
  <pvp-modal
    :value="value"
    width="580"
    class="full-width"
    @input="toggleModal"
  >
    <template slot="modal-title">{{
      $t('participants.searching')
    }}</template>
    <BaseForm @submit="searchParticipants">
      <BaseInput
        v-model="searchPhrase"
        :placeholder="$t('players.name_single')"
      >
        <Icon slot="before-input" name="search" inline />
      </BaseInput>

      <div v-show="players.length" class="list">
        <div
          v-for="(player, key) in players"
          :key="key"
          class="list-item"
        >
          <BaseUserLinkWithoutStore :data="player" :image-size="64" />
          <div class="roles">
            <BaseRadio
              v-for="(role, index) in inviteRoles"
              :key="index"
              :disabled="player.state !== undefined"
              :model-value="playersRoles[player.hash].role"
              :value="player.state ? '' : role"
              @input="setRadio(player.hash, { role })"
            >
              {{ $t(`players.role_${role}`) }}
            </BaseRadio>
          </div>
          <pvp-btn
            class="btn-add"
            :disabled="player.state !== undefined"
            :is-loading="playersRoles[player.hash].loading"
            @click="fetchInvite(player.hash)"
          >
            <template v-if="player.state">
              {{ $t(`organizations.member_${player.state}`) }}
            </template>
            <template v-else>
              {{ $t('global.invite') }}
            </template>
          </pvp-btn>
        </div>
      </div>

      <div v-if="hasError" class="not-found">
        {{ $t('errors.notFoundRequest') }}
      </div>

      <InfoBox v-if="error" class="error-msg" variant="error">
        {{ error }}
      </InfoBox>

      <pvp-btn
        :is-loading="isLoading"
        class="btn-submit"
        type="submit"
      >
        {{ $t('search.find') }}
      </pvp-btn>
    </BaseForm>
  </pvp-modal>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import BaseRadio from '@components/BaseComponents/Form/Radio.vue';
import Icon from '@components/v2/utils/Icon.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import BaseUserLinkWithoutStore from '@components/BaseComponents/BaseUserLinkWithoutStore.vue';
import { fetchAutoComplete } from '@src/shared/api/search';

export default {
  name: 'OrganizationNewParticipant',
  components: {
    BaseInput,
    BaseForm,
    BaseRadio,
    Icon,
    InfoBox,
    BaseUserLinkWithoutStore,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    searchPhrase: '',
    players: [],
    hasError: false,
    isLoading: false,
    playersRoles: {},
    currentParticipants: {},
    error: '',
  }),
  computed: {
    ...mapState('organizations', ['inviteRoles']),
  },

  watch: {
    value: {
      handler(isOpen) {
        if (isOpen === false) {
          return;
        }
        api
          .get(
            `/organization/${this.id}/invitation/list?pageSize=50&pageNum=1`,
          )
          .then(({ list }) => {
            this.currentParticipants = list.reduce(
              (result, item) => ({
                ...result,
                [item.hash]: item.state,
              }),
              {},
            );
          })
          .catch(() => ({}));
      },
      immediate: true,
    },
  },
  methods: {
    toggleModal(isOpen) {
      this.$emit('input', isOpen);
    },

    searchParticipants() {
      this.hasError = false;
      this.players = [];
      this.isLoading = true;
      this.playersRoles = {};

      fetchAutoComplete({
        searchPhrase: this.searchPhrase,
        recordsLimit: 5,
      })
        .then(({ items }) => {
          return {
            players: items.Player || {
              items: [],
              totalCount: 0,
            },
          };
        })
        .then(({ players }) => {
          if (players?.items?.length) {
            players.items.forEach((player) => {
              this.setRadio(player.hash, {
                role: this.inviteRoles[0],
              });
              this.players.push({
                ...player,
                state: this.currentParticipants?.[player.hash],
                hash: player.hash,
              });
            });
          } else {
            this.hasError = true;
          }
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setRadio(hash, value) {
      Vue.set(
        this.playersRoles,
        hash,
        _.merge({}, this.playersRoles?.[hash], value),
      );
    },

    fetchInvite(hash) {
      this.setRadio(hash, { loading: true });
      return api
        .post('/invitation/operation/create', {
          form: {
            targetId: this.id,
            targetEntity: 'organization',
            invitedHash: hash,
            role: this.playersRoles[hash].role,
          },
        })
        .then(() => {
          this.toggleModal(false);
        })
        .catch(({ error }) => {
          this.error = error || this.$t('errors.unknown');
        })
        .finally(() => {
          this.setRadio(hash, { loading: false });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.label ::v-deep {
  padding-left: 24;
  padding-right: 24;

  .input {
    padding-left: 40px;
  }

  .icon {
    color: rgba(white, 0.3);
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    left: 15px;
  }
}

.pvp-form {
  text-align: center;
}

.btn {
  &-submit {
    min-width: 160px;
    margin-top: 30px;
  }

  &-add {
    width: 128px;
    @include min-tablet() {
      margin-left: auto;
    }
    @include max-tablet() {
      margin-top: 12px;
    }
  }
}

.list {
  text-align: left;
  margin-top: 18px;

  &-item {
    background-color: rgba(black, 0.15);
    margin-top: 4px;
    padding: 18px 28px;

    @include min-tablet() {
      .button {
        align-self: center;
      }
      display: grid;
      grid-template-columns: 1fr 1fr 130px;
      grid-column-gap: 20px;
    }
    @include max-tablet() {
      position: relative;
      padding-left: 100px;
      display: flex;
      flex-direction: column;
      ::v-deep {
        .user-avatar {
          position: absolute;
          top: 18px;
          left: 25px;
        }

        .user-name {
          margin-left: 0;
        }
      }
    }
  }
}

.roles {
  font-size: 13px;
  @include min-tablet() {
    align-self: center;
  }
  @include max-tablet() {
    margin-top: 12px;
  }
}

.not-found {
  margin-top: 18px;
  font-size: 14px;
}

.error-msg {
  margin-top: 12px;
}
</style>
