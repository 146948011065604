<template>
  <div id="organization-tournaments" class="organization-tournaments">
    <div
      v-if="loadingState.page"
      class="loading-container"
      :class="{ bg: !!tournamentsIds.length }"
    >
      <loader :size="64" />
    </div>

    <template v-if="tournamentsIds.length">
      <tournaments-list
        ref="tournaments-list"
        class="tournaments-list"
        :tournaments-ids="tournamentsIds"
        :sort-order="sortOrder"
        @toggle-sort="onSortToggled"
      >
        <template v-if="canEditTournament" #status="{ tournament }">
          <tournament-status-edit
            :organization-id="id"
            :tournament-id="tournament.id"
          />
        </template>
      </tournaments-list>

      <pvp-pagination
        v-if="showPagination"
        :params="paginationParams"
        :disabled="loadingState.page"
        @onchange="setPage"
      />
    </template>
    <template v-else-if="!loadingState.page">
      <div class="tournaments-placeholder">
        <div class="placeholder-icon">
          <icon name="pvp-swords"></icon>
        </div>

        <div class="placeholder-text">
          {{ $t('tournaments.placeholders_filters') }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TournamentsList from './TournamentsList.vue';
import TournamentStatusEdit from './TournamentStatusEdit.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'OrganizationTournaments',
  components: {
    Icon,
    TournamentsList,
    TournamentStatusEdit,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    gameId: {
      type: Number,
      default: null,
    },
    canEditTournament: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      tournamentsIds: [],

      // Pagination
      currentPage: 1,
      perPage: 12,
      total: 0,
      perPageSizes: [12, 36, 72],

      // Sort
      sortFiled: 'date',
      sortOrder: 'asc',
    };
  },
  computed: {
    ...mapGetters('users', ['getUserProfile']),

    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
      };
    },

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },

    currentTab() {
      return this.tabs[this.currentTabIndex];
    },

    profile() {
      return this.getUserProfile(this.profileHash);
    },
  },
  watch: {
    paginationParams: {
      handler() {
        this.scrollToElement({
          element: _.get(this.$refs, 'tournaments-list.$el'),
          offset: -200,
        });
      },
    },
    type: {
      handler(type) {
        this.sortOrder = type === 4 ? 'desc' : 'asc';

        this.reloadTournaments(1);
      },
    },
  },
  created() {
    this.reloadTournaments();
  },
  methods: {
    ...mapActions('tournaments', ['fetchOrganizationTournaments']),

    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.reloadTournaments(page);
    },

    onSortToggled() {
      this.sortOrder =
        {
          asc: 'desc',
          desc: 'asc',
        }[this.sortOrder] || 'asc';

      this.reloadTournaments(1);
    },

    reloadTournaments(page = null) {
      if (page !== null) {
        this.currentPage = page;
      }

      this.loadingState.page = true;

      this.loadTournaments();
    },

    loadTournaments: function () {
      const fetchParams = {
        organizationId: this.id,
        type: this.type,
        page: this.currentPage,
        limit: this.perPage,
        sort: {
          field: this.sortFiled,
          order: this.sortOrder,
        },
      };

      if (this.gameId) {
        fetchParams.gameId = this.gameId;
      }

      this.fetchOrganizationTournaments(fetchParams)
        .then((result) => {
          this.tournamentsIds = result.tournaments;
          this.total = result.pagination.total;
        })
        .finally(() => {
          this.loadingState.page = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.organization-tournaments {
  min-height: 200px;
  position: relative;

  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }

  .loading-container {
    position: absolute;
    inset: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    padding-top: 100px;

    &.bg {
      background-color: rgba($dark, 0.8);
    }
  }

  .tournaments-list {
    margin-bottom: 8px;
  }

  .tournaments-placeholder {
    background-color: $dark-background;
    border-radius: 4px;
    box-shadow: $default-box-shadow;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .placeholder-icon {
      font-size: 30px;
    }

    .placeholder-text {
      text-align: center;
      margin-top: 12px;
      font-weight: bold;
      font-size: 24px;
    }

    .placeholder-action {
      margin-top: 12px;
    }
  }

  .tournaments-filter {
    margin-bottom: 20px;
  }
}
</style>
